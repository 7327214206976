import React from "react";
import { render } from "react-dom";
import Landing from "./Landing";
import About from "./About";
import Nav from "./Nav";
import Projects from "./Projects";
import Education from "./Education";
import Pico8Viewer from "./Pico8Viewer";

// import me from "./assets/me_and_schiele.jpg";

class App extends React.Component {
  state = {
    section: "landing"
  };
  handleClick = section => {
    // console.log(section);
    this.setState({ section });
  };
  render() {
    return (
      <div className="app">
        <Nav
          onclick={this.handleClick}
          sections={["landing", "about", "projects", "resume"]}
          cName="nav-dots"
        />
        <Landing onclick={this.handleClick} />
        <About />
        <Projects />
        <Education />
        {/* Icons made by{" "}
        <a href="https://icon54.com/" title="Pixel perfect">
          Pixel perfect
        </a>{" "}
        from{" "}
        <a href="https://www.flaticon.com/" title="Flaticon">
          {" "}
          www.flaticon.com
        </a> */}
      </div>
    );
  }
}

render(<App />, document.getElementById("root"));
