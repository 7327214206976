import React from "react";
import { useRef, useEffect, useState } from "react";

const { lerp } = require("canvas-sketch-util/math");
const random = require("canvas-sketch-util/random");
const palettes = require("nice-color-palettes");
const BezierEasing = require("bezier-easing");
const tinygradient = require("tinygradient");

const easeOutFn = BezierEasing(0, 0.32, 0.78, 0.86);
const easeInFn = BezierEasing(0.44, 0.01, 0.91, 0.81);

const createGrid = palette => {
  const points = [];
  const count = 200;
  for (let x = 0; x < count; x++) {
    for (let y = 0; y < count; y++) {
      const u = count <= 1 ? 0.5 : x / (count - 1);
      const v = count <= 1 ? 0.5 : y / (count - 1);
      const radius = Math.abs(random.noise2D(u * 2, v * 2)) * 0.095;

      const opacity = Math.abs(random.gaussian());
      let cIdx = Math.floor(
        radius * (10 * Math.abs(Math.log10(radius)) - 1) * palette.length
      );
      cIdx = cIdx != cIdx ? 0 : cIdx;

      if (radius > 0.009) {
        points.push({
          rotation: random.gaussian() * v * 0.5, //random.noise2D(u,v)*2,
          opacity,
          color: palette[cIdx], //random.pick(palette.slice(0, palette.length - 1)),
          radius,
          position: [u, v],
          fadeSpeed: random.pick([1, 2, 3]),
          fadeOffSet: random.value(),
          easeFn: random.pick([easeInFn, easeOutFn])
        });
      }
    }
  }
  return points;
};

function hexToRgb(hex) {
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;

  return r + "," + g + "," + b;
}

const Ukiyo = () => {
  // eslint-disable-next-line no-unused-vars
  const [dimensions, setDimensions] = useState({
    height: 2048,
    width: 2048
  });

  let ref = useRef();

  // let c_val = 0;
  useEffect(() => {
    let canvas = ref.current;

    let context = canvas.getContext("2d");

    random.setSeed(random.getRandomSeed());
    const palette = random.pick(palettes); //tinygradient([rPal[0], rPal[1]]).hsv(30,'long').map(e => '#'+e.toHex());
    const points = createGrid(palette).filter(data => {
      // const [x,y] = data.position;

      return random.value() < 0.5; // || random.gaussian() > y
    });
    let width = dimensions.width;
    let height = dimensions.height;
    const margin = 200;
    const bg = random.pick(palette); //'hsl(0,10%,90%)';

    canvas.width = width;

    canvas.height = height;
    // canvas.style.width = `${300}px`;
    // canvas.style.height = `${300}px`;

    const render = () => {
      context.globalAlpha = 1.0;

      context.fillStyle = bg;
      context.fillRect(0, 0, width, height);

      points.forEach(data => {
        const {
          rotation,
          opacity,
          color,
          position,
          radius,
          fadeSpeed,
          fadeOffSet,
          easeFn
        } = data;
        const [u, v] = position;
        const x = lerp(margin, width - margin, u);
        const y = lerp(margin, height - margin, v);

        context.beginPath();

        context.fillStyle =
          "rgba(" + hexToRgb(color.slice(1)) + "," + opacity + ")";
        context.arc(x, y, radius * width, 0, Math.PI * 2, false);
        context.fill();
      });
    };

    render();
  }, []);
  return (
    <div className="center">
      <canvas ref={ref} className="ukiyo" />
    </div>
  );
};

export default Ukiyo;
