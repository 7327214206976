import React from "react";

import { Link, animateScroll as scroll } from "react-scroll";

class Nav extends React.Component {
  state = {
    sections: this.props.sections,
    cName: this.props.cName,
    currSect: "landing",
    first: true
  };
  componentDidMount() {
    this.setState({ first: true });
  }
  handleSetActive = to => {
    this.setState({ currSect: to, first: false });
  };
  render() {
    const { sections, cName, currSect, first } = this.state;
    return (
      <div className="nav">
        <ul
          className={
            cName +
            " " +
            (currSect == "landing" ? "fadeout" : "fadein") +
            (first ? " hidden" : "")
          }
        >
          {sections.map(section => {
            return (
              <li key={section}>
                <Link
                  onClick={() => this.props.onclick(section)}
                  className="nav-section"
                  activeClass="active"
                  to={section}
                  spy={true}
                  smooth={true}
                  offset={-10}
                  duration={500}
                  onSetActive={this.handleSetActive}
                >
                  {section}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

export default Nav;
