const p_fills = [
  {
    skill: "JavaScript",
    value: `85%`,
    icon: "mdi-language-javascript",
    angle: "deg0"
  },
  {
    skill: "CSS",
    value: `70%`,
    icon: "mdi-language-css3",
    angle: "deg36"
  },
  {
    skill: "HTML5",
    value: `75%`,
    icon: "mdi-language-html5",
    angle: "deg72"
  },
  {
    skill: "React",
    value: `65%`,
    icon: "mdi-react",
    angle: "deg108"
  },
  {
    skill: "Git/Github",
    value: `70%`,
    icon: "mdi-github",
    angle: "deg144"
  },
  {
    skill: "NodeJS",
    value: `80%`,
    icon: "mdi-nodejs",
    angle: "deg180"
  },
  {
    skill: "NPM",
    value: `85%`,
    icon: "mdi-npm",
    angle: "deg216"
  },
  {
    skill: "Java",
    value: `90%`,
    icon: "mdi-language-java",
    angle: "deg252"
  },
  {
    skill: "SQL",
    value: `60%`,
    custom: true,
    angle: "deg288"
  },
  {
    skill: "Spring",
    value: `75%`,
    custom: true,
    angle: "deg324"
  }
];

export { p_fills };
