import React from "react";
import { useState } from "react";
import ReactDOM from "react-dom";

const Modal = ({ isShowing, hide, root, modalContent, children }) => {
  let [currChild, setChild] = useState(0);
  children = children.filter(e => !!e);
  console.log(children);
  function nextChild() {
    if (currChild < children.length - 1) {
      setChild(currChild + 1);
    } else {
      setChild(0);
    }
  }
  function prevChild() {
    if (currChild > 0) {
      setChild(currChild - 1);
    } else {
      setChild(children.length - 1);
    }
  }
  return isShowing
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="modal-overlay" />
          <div
            className="modal-wrapper"
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
          >
            <div className="modal">
              <div className="modal-header">
                <button
                  type="button"
                  className="modal-close-button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={hide}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-children-container">
                {modalContent == "gart" && (
                  <p className="loading">loading...</p>
                )}
                {children[currChild]}
                {console.log(children.length)}
                {children.length > 0 && (
                  <div>
                    <a className="prev" onClick={prevChild}>
                      &#10094;
                    </a>
                    <a className="next" onClick={nextChild}>
                      &#10095;
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>,
        document.getElementById(root)
      )
    : null;
};
export default Modal;
