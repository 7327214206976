import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.parcel";
import RichardMichelsResume from "./assets/RichardMichelsResume.pdf";

const Resume = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <Document
        file={RichardMichelsResume}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          className="resume-page"
          pageNumber={pageNumber}
          renderAnnotationLayer={false}
          renderTextLayer={true}
        />
      </Document>
      <a href={RichardMichelsResume} download="RichardMichelsResume.pdf">
        download resume
      </a>
    </div>
  );
};

export default Resume;
