import React from "react";
import { useRef, useEffect, useState } from "react";
import { throttled, dist, debounce } from "./util.js";

const { lerp } = require("canvas-sketch-util/math");
const random = require("canvas-sketch-util/random");
const palettes = require("nice-color-palettes");

const createGrid = palette => {
  const points = [];
  const count = 50;
  for (let x = 0; x < count; x++) {
    for (let y = 0; y < count; y++) {
      const u = count <= 1 ? 0.5 : x / (count - 1);
      const v = count <= 1 ? 0.5 : y / (count - 1);
      const radius = Math.abs(random.noise2D(u, v)) * 0.09;
      const opacity = 1; //Math.abs(random.gaussian());

      points.push({
        rotation: random.gaussian() * v * 0.5, //random.noise2D(u,v)*2,
        opacity,
        color: random.pick(palette.slice(0, palette.length - 1)),
        radius,
        position: [u, v]
      });
    }
  }
  return points;
};

const Unbuild = () => {
  // eslint-disable-next-line no-unused-vars
  const [dimensions, setDimensions] = useState({
    height: 2048,
    width: 2048
  });

  let ref = useRef();

  // let c_val = 0;
  useEffect(() => {
    let canvas = ref.current;

    let context = canvas.getContext("2d");

    let width = dimensions.width;
    let height = dimensions.height;

    let margin = 200;
    random.setSeed(random.getRandomSeed());
    const palette = random.shuffle(random.pick(palettes)); //.slice(0, colorCount);
    const points = createGrid(palette).filter(data => {
      const [x, y] = data.position;

      return random.value() < 0.5 || random.gaussian() > y;
    });
    canvas.width = width;

    canvas.height = height;
    // canvas.style.width = `${300}px`;
    // canvas.style.height = `${300}px`;

    let requestId;
    let i = 0;

    let c_val;
    const render = () => {
      context.globalAlpha = 1.0;
      context.fillStyle = palette[palette.length - 1];
      context.fillRect(0, 0, width, height);
      points.forEach(data => {
        const { rotation, opacity, color, position, radius } = data;
        const [u, v] = position;
        const x = lerp(margin, width - margin, u);
        const y = lerp(margin, height - margin, v);

        context.save();
        context.fillStyle = color;
        // context.font = `${width*radius}px "Helvetica"`
        context.font = `30px "Zapfino"`;

        context.translate(x, y - 100);
        context.rotate(Math.PI / 2 + rotation);

        context.scale(10, (1 - radius) * 1.2);

        context.fillText("|", 0, 0);
        context.restore();
      });
    };

    render();
  }, []);
  return (
    <div className="center">
      <canvas ref={ref} className="unbuild" />
    </div>
  );
};

export default Unbuild;
