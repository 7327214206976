import React from "react";
import { useState } from "react";
import Unbuild from "./Unbuild";
import Modal from "./Modal";
import useModal from "./useModal";
import Ukiyo from "./Ukiyo";
import Furbar from "./Furbar";

const Projects = ({ section }) => {
  const [isShowing, toggle] = useModal();
  const [modalContent, setModalContent] = useState("");

  return (
    <section id="projects">
      <div className="container">
        <div className="projects-head">
          <h1>Projects</h1>
        </div>
        <div className="projects-sub-head">
          <h2>Personal projects</h2>
        </div>
        <div className="project-statements projects-grid">
          <div className={"project" + (section == "projects" ? " zoomin" : "")}>
            <h2 className="project-title">
              <a
                className="button-default"
                onClick={() => {
                  setModalContent("game");
                  toggle();
                }}
              >
                <svg
                  id="exp-game"
                  className="project-modal-expander"
                  height="14"
                  viewBox="0 0 24 24"
                  width="14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g color="white">
                    <path
                      fill="currentcolor"
                      stroke="currentcolor"
                      d="m18.5 23h-15c-1.378 0-2.5-1.122-2.5-2.5v-15c0-1.378 1.122-2.5 2.5-2.5h9c.276 0 .5.224.5.5s-.224.5-.5.5h-9c-.827 0-1.5.673-1.5 1.5v15c0 .827.673 1.5 1.5 1.5h15c.827 0 1.5-.673 1.5-1.5v-9c0-.276.224-.5.5-.5s.5.224.5.5v9c0 1.378-1.122 2.5-2.5 2.5z"
                    />
                  </g>
                  <g color="white">
                    <path
                      fill="currentcolor"
                      stroke="currentcolor"
                      d="m22.5 9c-.276 0-.5-.224-.5-.5v-6.5h-6.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h7c.276 0 .5.224.5.5v7c0 .276-.224.5-.5.5z"
                    />
                  </g>
                  <g color="white">
                    <path
                      fill="currentcolor"
                      stroke="currentcolor"
                      d="m10.5 14c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707l12-12c.195-.195.512-.195.707 0s.195.512 0 .707l-12 12c-.097.097-.225.146-.353.146z"
                    />
                  </g>
                </svg>
              </a>
              Games in PICO-8
            </h2>
            <p className="project-body">
              I have always been a huge proponent of interactive media. Game
              development has provided the perfect avenue to explore that
              interest while building my programming skillset. In the modal
              window I have included three game projects (1 completed, 2 WIP)
              for which I produced the code, artwork and music. These projects
              were created using PICO-8, an all inclusive game development
              environment that uses a subset of the Lua programming language.
            </p>
          </div>
          <div className={"project" + (section == "projects" ? " zoomin" : "")}>
            <h2 className="project-title">
              <a
                className="button-default"
                onClick={() => {
                  setModalContent("gart");
                  toggle();
                }}
              >
                <svg
                  id="exp-gart"
                  className="project-modal-expander"
                  height="14"
                  viewBox="0 0 24 24"
                  width="14"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g color="white">
                    <path
                      fill="currentcolor"
                      stroke="currentcolor"
                      d="m18.5 23h-15c-1.378 0-2.5-1.122-2.5-2.5v-15c0-1.378 1.122-2.5 2.5-2.5h9c.276 0 .5.224.5.5s-.224.5-.5.5h-9c-.827 0-1.5.673-1.5 1.5v15c0 .827.673 1.5 1.5 1.5h15c.827 0 1.5-.673 1.5-1.5v-9c0-.276.224-.5.5-.5s.5.224.5.5v9c0 1.378-1.122 2.5-2.5 2.5z"
                    />
                  </g>
                  <g color="white">
                    <path
                      fill="currentcolor"
                      stroke="currentcolor"
                      d="m22.5 9c-.276 0-.5-.224-.5-.5v-6.5h-6.5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h7c.276 0 .5.224.5.5v7c0 .276-.224.5-.5.5z"
                    />
                  </g>
                  <g color="white">
                    <path
                      fill="currentcolor"
                      stroke="currentcolor"
                      d="m10.5 14c-.128 0-.256-.049-.354-.146-.195-.195-.195-.512 0-.707l12-12c.195-.195.512-.195.707 0s.195.512 0 .707l-12 12c-.097.097-.225.146-.353.146z"
                    />
                  </g>
                </svg>
              </a>
              Generative Art
            </h2>
            <div className="project-body" id="unbuild-container">
              Computer affordances allow creative expression and the creation of
              novel and unique art. In the modal window you can view a selection
              of three pieces of generative art I coded into react components.
              These pieces explore the relationship between pure random number
              generation, 2d noise and gaussian distribution of random numbers.
              Each component uses react hooks with the useEffect lifecycle
              method to generate a totally new piece every time the component is
              rendered.
            </div>
          </div>
          <div className={"project" + (section == "projects" ? " zoomin" : "")}>
            <h2 className="project-title">Personal Website</h2>
            <p className="project-body">
              In an effort to expand my skillset I decided to make my site using
              the ReactJS library. For this project I developed a component for
              rendering an HTML canvas element with generative art code. I also
              made significant use of React Hooks in order to reduce the amount
              of boiler plate code related to component lifecycle methods.
            </p>
          </div>
        </div>
        <div className="projects-sub-head">
          <h2>Paid work</h2>
        </div>
        <div className="project-statements projects-grid">
          <div className={"project" + (section == "projects" ? " zoomin" : "")}>
            <h2 className="project-title">AMIS</h2>
            <p className="project-body">
              AMIS is an invoicing application used by money management firms.
              Major work I completed for this project includes an app-wide front
              end resdesign, development of several SQL stored procedures and
              backend API methods. I also provide ongoing maintenance and
              feature implementation.
            </p>
          </div>
          <div className={"project" + (section == "projects" ? " zoomin" : "")}>
            <h2 className="project-title">GONDO</h2>
            <p className="project-body">
              GONDO provides distribution services for money management reports.
              My significant contributions include implementing secure LDAP
              autentication and building an FTP client class used for PDF file
              transfers.
            </p>
          </div>
          <div className={"project" + (section == "projects" ? " zoomin" : "")}>
            <h2 className="project-title">Reportfolio</h2>
            <p className="project-body">
              Reportfolio is a PDF design application. I have provided regular
              maintenence and bug fixes ranging from front end UI clean up to
              major ticket issues related to file input and output streams.
            </p>
          </div>
        </div>

        <Modal
          isShowing={isShowing}
          hide={toggle}
          root="projects"
          modalContent={modalContent}
        >
          {modalContent == "game" && (
            <div className="pico-8-color-bg">
              <span className="game-title">Sub Hunter</span>
              <div className="pico-8-tv">
                <iframe
                  className="pico-8-game"
                  title="Sub Hunter"
                  src="https://subhunter.surge.sh/"
                  frameBorder="0"
                ></iframe>
                <span className="game-info">
                  I made Sub Hunter as a self-imposed challenge to develop and
                  complete a game within a 24 hour period. This was an extremely
                  fun exercise that yielded a compelling but simple arcade style
                  game. Through the process of creating Sub Hunter I learned how
                  to accept a project as finished move on to the next objective.
                </span>
              </div>
            </div>
          )}
          {modalContent == "game" && (
            <div className="pico-8-color-bg">
              <span className="game-title">Galactic Memory</span>
              <div className="pico-8-tv">
                <iframe
                  className="pico-8-game"
                  title="Galactic Memory"
                  src="https://galactic-memory.surge.sh/"
                  frameBorder="0"
                ></iframe>
                <span className="game-info">
                  Galactic Memory features and infintely generating apparently
                  persistant galaxy. Using seeded random number generation I
                  create a sector of the galaxy using that sector&apos;s
                  coordinates as the seed. In this way I am able to "query" this
                  seeded random number to generate stars. I am able to generate
                  planets for those stars using that star&apos;s sub-coordinate
                  within the sector for a seeded random number that applies to
                  that star. Because the random numbers generated this way are
                  seeded based on static coordinate values every sector and star
                  system gives the appearence of persistance. In reality they
                  are being generated "randomly" the same way every time.
                </span>
              </div>
            </div>
          )}
          {modalContent == "game" && (
            <div className="pico-8-color-bg">
              <span className="game-title">Ninja Jump</span>
              <div className="pico-8-tv">
                <iframe
                  className="pico-8-game"
                  title="Ninja Jump"
                  src="https://ninja-jump.surge.sh/"
                  frameBorder="0"
                ></iframe>
                <span className="game-info">
                  Ninja Jump is a 2D platformer based around the idea of
                  secondary jumps gained by shooting floating coins. This is one
                  of my earlier projects and through it I learned the basics of
                  game loop logic and entity management. This project also
                  provided an avenue for understanding simple physics and
                  collision detection.
                </span>
              </div>
            </div>
          )}
          {modalContent == "gart" && (
            <div className="gen-art-container">
              <Furbar />
              <span className="gen-art-title">FURBAR</span>
            </div>
          )}
          {modalContent == "gart" && (
            <div className="gen-art-container">
              <Ukiyo />
              <span className="gen-art-title">UKIYO SEUSS</span>
            </div>
          )}
          {modalContent == "gart" && (
            <div className="gen-art-container">
              <Unbuild />
              <span className="gen-art-title">UNBUILD</span>
            </div>
          )}
        </Modal>
      </div>
    </section>
  );
};

export default Projects;
