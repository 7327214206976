import React, { useState } from "react";

const Pico8Viewer = () => {
  const games = [
    { title: "Sub Hunter", source: "http://subhunter.surge.sh/" },
    { title: "Galactic Search", source: "http://galactic-search.surge.sh/" },
    { title: "Ninja Jump", source: "http://ninja-jump.surge.sh/" }
  ];
  const [game, setGame] = useState(games[0]);

  return (
    <section id="pico8Viewer">
      <div className="pico-8-color-bg">
        <div className="container">
          <div className="pico-8-tv">
            <iframe
              className="pico-8-game"
              title={game.title}
              src={game.source}
              frameBorder="0"
            ></iframe>
          </div>
          <div className="game-select-container">
            {games.map((game, index) => (
              <button
                className="game-select"
                key={game.title}
                onClick={e => {
                  e.preventDefault();
                  setGame(games[index]);
                }}
              >
                {game.title}
              </button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pico8Viewer;
