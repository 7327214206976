import React from "react";
import Resume from "./Resume";

const Education = () => {
  return (
    <section id="resume">
      <div className="container">
        <Resume />
      </div>
    </section>
  );
};

export default Education;
