import React from "react";
import { useRef, useEffect, useState } from "react";
import { throttled, dist, debounce } from "./util.js";

const { lerp } = require("canvas-sketch-util/math");
const random = require("canvas-sketch-util/random");

const getPixelRatio = context => {
  var backingStore =
    context.backingStorePixelRatio ||
    context.webkitBackingStorePixelRatio ||
    context.mozBackingStorePixelRatio ||
    context.msBackingStorePixelRatio ||
    context.oBackingStorePixelRatio ||
    context.backingStorePixelRatio ||
    1;

  return (window.devicePixelRatio || 1) / backingStore;
};
const createGrid = (width, height) => {
  width = +width;
  height = +height;
  const points = [];
  const count = width > height ? width / 14 : height / 14;
  let count_x, count_y;
  if (width > height) {
    count_x = count;
    count_y = Math.floor(height / (width / count));
  } else {
    count_x = Math.floor(width / (height / count));
    count_y = count;
  }
  for (let x = 0; x < count_x; x++) {
    for (let y = 0; y < count_y; y += 1) {
      const u = count_x <= -1 ? 0.5 : x / (count_x - 2);
      const v = count_y <= -1 ? 0.5 : y / (count_y - 2);
      const opacity = Math.abs(random.noise2D(u, v, 3, 0.5));
      // eslint-disable-next-line no-constant-condition
      if (true) {
        points.push({
          opacity,
          position: [u, v] //[Math.abs(random.noise2D(-v, u, random.value(), random.value()) * 1.55) - 0.1, Math.abs(random.noise2D(-v, u,random.value(), random.value()) * 1.55)-0.1],
        });
      }
    }
  }
  return points;
};

const margin = 0;
const framesPerSecond = 60;
let mousex, mousey;

const handleMouseMove = event => {
  if (window.pageYOffset > 0) return;
  mousex = event.clientX;
  mousey = event.clientY;
};

const tHandleMouseMove = throttled(5, handleMouseMove);
const Circle = () => {
  // eslint-disable-next-line no-unused-vars
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });
  const debouncedHandleResize = debounce(function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth
    });
  }, 1000);

  let ref = useRef();

  // let c_val = 0;
  useEffect(() => {
    let canvas = ref.current;

    let context = canvas.getContext("2d");

    let ratio = getPixelRatio(context);
    let width = getComputedStyle(canvas)
      .getPropertyValue("width")
      .slice(0, -2);
    let height = getComputedStyle(canvas)
      .getPropertyValue("height")
      .slice(0, -2);
    const points = createGrid(width, height);
    canvas.width = width * ratio;

    canvas.height = height * ratio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    window.addEventListener("resize", debouncedHandleResize);
    window.addEventListener("mousemove", tHandleMouseMove);
    // window.addEventListener("click", e => {
    //   random.permuteNoise();
    // });
    window.addEventListener("scroll", () => {
      let scrollThen;
      scrollThen = window.pageYOffset;
      setTimeout(() => {
        if (window.pageYOffset == 0 && scrollThen == 0) {
          render();
        }
      }, 10);
    });

    let requestId;
    let i = 0;

    let c_val;
    const render = () => {
      setTimeout(function() {
        context.clearRect(0, 0, canvas.width, canvas.height);

        for (let p = 0; p < points.length; p++) {
          const { position } = points[p];
          const [u, v] = position;
          const x = Math.floor(lerp(margin, canvas.width - margin, u));
          const y = Math.floor(lerp(margin, canvas.height - margin, v));

          if (y > canvas.height * 2) continue;
          context.save();
          context.beginPath();
          context.moveTo(x, y);
          context.translate(x, y);
          //   context.rotate((((i * 10) % 360) * Math.PI) / 180);

          // context.rect(0, 0, 28, 28);
          let dst = dist(
            mousex * ratio,
            mousey * ratio,
            Math.floor(x) + 14,
            Math.floor(y) + 14
          );
          c_val = Math.abs(random.noise3D(u, v, i / 200, 2, 1.5));
          if (c_val >= 0.5) {
            c_val = 0.5;
          }
          if (dst < 200) {
            context.fillStyle =
              "rgba(" +
              [240, 240, 240 * ((dst * 2) / 400)].join(",") +
              "," +
              (1 - c_val - 0.5) +
              ")";
          } else {
            context.fillStyle =
              "rgba(" +
              [240, 240, 240].join(",") +
              "," +
              (1 - c_val - 0.5) +
              ")";
          }
          context.arc(0, 0, (1 - c_val) * 10, 0, 2 * Math.PI);
          context.fill();
          context.restore();
        }
        i += 1 * 1;

        if (window.pageYOffset == 0) {
          requestId = requestAnimationFrame(render);
        } else {
          cancelAnimationFrame(requestId);
        }
      }, 1000 / framesPerSecond);
    };

    render();
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
      cancelAnimationFrame(requestId);
    };
  }, []);
  return (
    <div>
      <canvas ref={ref} className="circle" />
    </div>
  );
};

export default Circle;
