import React, { useState } from "react";
import springIcon from "./assets/images/icons8-spring-48.png";
import about_pic from "./assets/images/richard-profile.png";
import { p_fills } from "./Constants";

const percent_fill = w => ({
  position: `absolute`,
  backgroundColor: `#0098dd`,
  content: `"\A"`,
  top: `0px`,
  height: `16px`,
  zIndex: `-10`,
  width: w
});

const About = ({ section }) => {
  const [isShown, setIsShown] = useState(false);
  return (
    <section id="about">
      <div className="container">
        <div className="about-head">
          <h1>About</h1>
        </div>
        <div className="block-statements grid">
          <div className={"item" + (section == "about" ? " zoomin" : "")}>
            <h2 className="item-title">functional</h2>
            <p className="item-body">
              Let&apos;s cut to the bone of what needs to happen. I love
              creating practical solutions to real problems.
            </p>
          </div>
          <div className={"item" + (section == "about" ? " zoomin" : "")}>
            <h2 className="item-title">adaptable</h2>
            <p className="item-body">
              I don&apos;t believe in silver bullets. I relish the opportunity
              to learn new skills and take on extra responsibilites.
            </p>
          </div>
          <div className={"item" + (section == "about" ? " zoomin" : "")}>
            <h2 className="item-title">collaborative</h2>
            <p className="item-body">
              I am a flexible professional who embraces teamwork. Let&apos;s
              work together to build something amazing.
            </p>
          </div>
          <div className={"item" + (section == "about" ? " zoomin" : "")}>
            <h2 className="item-title">creative</h2>
            <p className="item-body">
              Complex problems require innovative thinking. I have a strong
              preference for the dynamic approach through critical analysis.
            </p>
          </div>
        </div>
        <div className={"wheel-house" + (section == "about" ? " zoomin" : "")}>
          <div className="abpic-con">
            <div className="about-pic"></div>
            <h1>Hi,</h1> I&apos;m a full stack web application developer in
            Brooklyn, NY. I want to help you create the software you want and
            broaden my developer skillset along the way.
          </div>
          <div className="skicon-con">
            <div
              className="skills-icons"
              onClick={() => setIsShown(!isShown)}
              // onMouseLeave={() => setIsShown(false)}
            >
              <div className="skills-text">my wheel house</div>
              {p_fills.map(s => {
                const ico =
                  s.skill == "SQL" ? (
                    <span className="custom-icon-container">
                      <i className="mdi mdi-database"></i>
                      <span className="sql-logo">SQL</span>
                    </span>
                  ) : s.skill == "Spring" ? (
                    <img src={springIcon} alt="spring" />
                  ) : (
                    <i className={"mdi " + s.icon}></i>
                  );
                const ang_val = +s.angle.slice(3);
                return (
                  <a className={s.angle + " skill-icon"}>
                    {ico}
                    <div
                      className={
                        (isShown ? "fadein" : "fadeout") +
                        " skill-bars" +
                        (ang_val >= 108 && ang_val < 288 ? "-left" : "")
                      }
                    >
                      <p>{s.skill + " " + s.value}</p>
                      <div>
                        <div style={percent_fill(s.value)}></div>
                      </div>
                    </div>
                  </a>
                );
              })}
            </div>
            <div className="mobile-skills-icons">
              {p_fills.map(s => {
                const ico =
                  s.skill == "SQL" ? (
                    <span className="custom-icon-container">
                      <i className="mdi mdi-database"></i>
                      <span className="sql-logo"></span>
                    </span>
                  ) : s.skill == "Spring" ? (
                    <i>
                      <img src={springIcon} alt="spring" />
                    </i>
                  ) : (
                    <i className={"mdi " + s.icon}></i>
                  );
                return (
                  <div className="mobile-skills-icon" key={s.skill}>
                    {ico}
                    <div className="mobile-skill-bars">
                      <p>{s.skill + " " + s.value}</p>
                      <div style={percent_fill(s.value)}></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
